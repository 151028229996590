import React from 'react';
import Link from 'next/link';

interface FooterProps {
  source?: string | null;
  /**
   * Optional Tailwind CSS color ex: bg-purple-200
   * Defaults to none
   */
  color?: string | null | undefined;
}

export function Footer({ color, source }: FooterProps): JSX.Element | null {
  const backgroundColor = color ? color : '';
  // If we are in a webview, we don't want to show the footer
  if (source === 'webview') {
    return null;
  }

  return (
    <div
      data-testid="footerContainer"
      className={`flex justify-start pt-12 pb-4 px-4 ${backgroundColor}`}
    >
      <small className="text-gray-500 mr-6" data-testid="copyright">
        © Qeepsake, Inc.
      </small>
      <small className="mr-6">
        <Link
          href="/policies/terms"
          className="cursor-pointer text-gray-500"
          data-testid="termsOfServiceLink"
        >
          Terms of Service
        </Link>
      </small>
      <small className="mr-6">
        <Link
          href="/policies/privacy"
          className="cursor-pointer text-gray-500"
          data-testid="privacyPolicyLink"
        >
          Privacy Policy
        </Link>
      </small>
      <small>
        <Link
          href="https://help.qeepsake.com/hc/en-us"
          target="_blank"
          className="cursor-pointer text-gray-500"
          data-testid="helpCenterLink"
        >
          Help Center
        </Link>
      </small>
    </div>
  );
}
