// lib/mixpanel.ts
import mixpanel from 'mixpanel-browser';

let isInitialized = false;

/**
 * Initializes Mixpanel only once.
 * @param {string} token - Your Mixpanel project token
 */
export function initMixpanel(token: string): void {
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' &&
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging'
  ) {
    return;
  }

  if (!isInitialized) {
    mixpanel.init(token, {
      debug: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging',
    });
    isInitialized = true;
  }
}

export function setGlobalProperties(props: Record<string, any>): void {
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' &&
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging'
  ) {
    return;
  }

  if (!isInitialized) {
    return;
  }

  mixpanel.register(props);
}


/**
 * Tracks a Mixpanel event.
 * @param {string} eventName - The name of the event to track
 * @param {Record<string, any>} props - Optional properties to send with the event
 */
export function trackEvent(eventName: string, props: Record<string, any> = {}): void {
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' &&
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging'
  ) {
    return;
  }

  if (!isInitialized) {
    return;
  }
  mixpanel.track(eventName, props);
}

/**
 * @param {string} distinctId - A unique user identifier
 * @param {Record<string, any>} props - Properties to set on the user profile
 */
export function identifyUser(distinctId: string, props: Record<string, any> = {}): void {
  // Only identify if we're in production OR staging
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' &&
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging'
  ) {
    return;
  }

  if (!isInitialized) {
    return;
  }
  mixpanel.identify(distinctId);
  if (Object.keys(props).length > 0) {
    mixpanel.people.set(props);
  }
}
